import React from 'react'

import Page from '../templates/Page'

const Agb = () => (
  <Page title="Datenschutzerklärung">
    <b>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</b>
    <p>
      Tom Heinze <br />
      Blankenburger Straße 45
      <br /> 13156 Berlin
    </p>
    <p>
      Soweit nachstehend keine anderen Angaben gemacht werden, ist die
      Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
      vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich.
      Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine
      Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den
      nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht
      wird.
      <br />
      “Personenbezogene Daten” sind alle Informationen, die sich auf eine
      identifizierte oder identifizierbare natürliche Person beziehen.
    </p>
    <p>
      <strong>Server-Logfiles</strong>
      <br />
      Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu
      machen.
      <br />
      Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster
      / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser übermittelt
      und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen
      gespeicherten Daten gehören z.B. der Name der aufgerufenen Seite, Datum
      und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der
      anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6
      Abs. 1 lit. f DSGVO aus berechtigtem Interesse an der Gewährleistung eines
      störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres
      Angebotes.
    </p>
    <p>
      <strong>Kundenkonto</strong>
      <br />
      Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen
      Daten in dem dort angegeben Umfang. Die Datenverarbeitung dient dem Zweck,
      Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu
      vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a
      DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit durch
      Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund
      der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr
      Kundenkonto wird anschließend gelöscht.
    </p>
    <p>
      Darüber hinaus wird bei Abgabe des Kommentars Ihre IP-Adresse gespeichert
      zu dem Zweck, einen Missbrauch der Kommentarfunktion zu verhindern und die
      Sicherheit unserer informationstechnischen Systeme sicherzustellen. Mit
      Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten
      Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a
      DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit durch
      Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund
      der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
      Ihre IP-Adresse wird anschließend gelöscht.
    </p>
    <p>
      <strong>
        Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei
        Bestellungen
      </strong>
      <br />
      Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen
      Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer Bestellung sowie
      zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der
      Daten ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung
      hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung
      erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die
      Erfüllung eines Vertrags mit Ihnen erforderlich.
      <br />
      Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen
      gewählten Versandunternehmen und Dropshipping Anbieter,
      Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und
      IT-Dienstleister. In allen Fällen beachten wir strikt die gesetzlichen
      Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein
      Mindestmaß.
    </p>
    <p>
      <strong>
        Verwendung der E-Mail-Adresse für die Zusendung von Newslettern
      </strong>
      <br />
      Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung
      ausschließlich für eigene Werbezwecke zum Newsletterversand, sofern Sie
      dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage
      des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können die
      Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der
      aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt
      wird. Sie können dazu den Newsletter jederzeit unter Nutzung des
      entsprechenden Links im Newsletter oder durch Mitteilung an uns
      abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.
    </p>
    <p>
      <strong>
        Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung
      </strong>
      <br />
      Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware
      oder Dienstleistung erhalten haben, für die elektronische Übersendung von
      Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind,
      die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht
      widersprochen haben. Die Bereitstellung der E-Mail-Adresse ist für den
      Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass
      kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf
      Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigtem Interesse an
      Direktwerbung. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit
      durch Mitteilung an uns widersprechen. Die Kontaktdaten für die Ausübung
      des Widerspruchs finden Sie im Impressum. Sie können auch den dafür
      vorgesehenen Link in der Werbe-E-Mail nutzen. Hierfür entstehen keine
      anderen als die Übermittlungskosten nach den Basistarifen.
    </p>
    <p>
      <strong>Verwendung von PayPal</strong>
      <br />
      Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung.
      Diese finden Sie unter{' '}
      <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE">
        https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE
      </a>
    </p>
    <p>
      <strong>
        Zahlungsart: Datenschutzbestimmungen zu PayPal als Zahlungsart
      </strong>
      <br />
      Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
      Komponenten von PayPal integriert. PayPal ist ein
      Online-Zahlungsdienstleister. Zahlungen werden über sogenannte
      PayPal-Konten abgewickelt, die virtuelle Privat- oder Geschäftskonten
      darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle Zahlungen
      über Kreditkarten abzuwickeln, wenn ein Nutzer kein PayPal-Konto
      unterhält. Ein PayPal-Konto wird über eine E-Mail-Adresse geführt, weshalb
      es keine klassische Kontonummer gibt. PayPal ermöglicht es,
      Online-Zahlungen an Dritte auszulösen oder auch Zahlungen zu empfangen.
      PayPal übernimmt ferner Treuhänderfunktionen und bietet
      Käuferschutzdienste an.
    </p>
    <p>
      Die Europäische Betreibergesellschaft von PayPal ist die PayPal (Europe)
      S.à.r.l. &amp; Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg,
      Luxemburg.
    </p>
    <p>
      Wählt die betroffene Person während des Bestellvorgangs in unserem
      Online-Shop als Zahlungsmöglichkeit „PayPal“ aus, werden automatisiert
      Daten der betroffenen Person an PayPal übermittelt. Mit der Auswahl dieser
      Zahlungsoption willigt die betroffene Person in die zur Zahlungsabwicklung
      erforderliche Übermittlung personenbezogener Daten ein.
    </p>
    <p>
      Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich in
      der Regel um Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse,
      Telefonnummer, Mobiltelefonnummer oder andere Daten, die zur
      Zahlungsabwicklung notwendig sind. Zur Abwicklung des Kaufvertrages
      notwendig sind auch solche personenbezogenen Daten, die im Zusammenhang
      mit der jeweiligen Bestellung stehen.
    </p>
    <p>
      Die Übermittlung der Daten bezweckt die Zahlungsabwicklung und die
      Betrugsprävention. Der für die Verarbeitung Verantwortliche wird PayPal
      personenbezogene Daten insbesondere dann übermitteln, wenn ein
      berechtigtes Interesse für die Übermittlung gegeben ist. Die zwischen
      PayPal und dem für die Verarbeitung Verantwortlichen ausgetauschten
      personenbezogenen Daten werden von PayPal unter Umständen an
      Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
      Identitäts- und Bonitätsprüfung.
    </p>
    <p>
      PayPal gibt die personenbezogenen Daten gegebenenfalls an verbundene
      Unternehmen und Leistungserbringer oder Subunternehmer weiter, soweit dies
      zur Erfüllung der vertraglichen Verpflichtungen erforderlich ist oder die
      Daten im Auftrag verarbeitet werden sollen.
    </p>
    <p>
      Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
      personenbezogenen Daten jederzeit gegenüber PayPal zu widerrufen. Ein
      Widerruf wirkt sich nicht auf personenbezogene Daten aus, die zwingend zur
      (vertragsgemäßen) Zahlungsabwicklung verarbeitet, genutzt oder übermittelt
      werden müssen.
    </p>
    <p>
      Die geltenden Datenschutzbestimmungen von PayPal können unter
      https://www.paypal.com/de/webapps/mpp/ua/privacy-full abgerufen werden.
    </p>
    <p>
      <strong>Stripe</strong>
      <br />
      Wenn Sie sich für eine über den Zahlungsdienstleister „Stripe“ angebotene
      Zahlungsart entscheiden, erfolgt die Zahlungsabwicklung über die Stripe
      Payments Europe Ltd., C/O A&amp;L Goodbody, Ifsc, North Wall Quay, Dublin
      1, Irland, an die wir Ihre im Rahmen des Bestellvorgangs mitgeteilten
      Informationen nebst den Informationen über Ihre Bestellung (Name,
      Anschrift, Kontonummer, Bankleitzahl, evtl. Kreditkartennummer,
      Rechnungsbetrag, Währung und Transaktionsnummer) gemäß Art. 6 Abs. 1 lit.
      b DSGVO weitergeben. Die Weitergabe Ihrer Daten erfolgt ausschließlich zum
      Zwecke der Zahlungsabwicklung mit der Stripe Payments Europe Ltd. und nur
      insoweit, als sie hierfür erforderlich ist. Nähere Informationen zum
      Datenschutz von „Stripe“ finden Sie unter der nachstehenden
      Internetadresse: https://stripe.com/de/privacy#translation.
    </p>
    <p>
      <strong>SOFORT</strong>
      <br />
      Bei Auswahl der Zahlungsart „SOFORT“ erfolgt die Zahlungsabwicklung über
      den Zahlungsdienstleister SOFORT GmbH, Theresienhöhe 12, 80339 München,
      Deutschland (im Folgenden „SOFORT“), an den wir Ihre im Rahmen des
      Bestellvorgangs mitgeteilten Informationen nebst den Informationen über
      Ihre Bestellung gemäß Art. 6 Abs. 1 lit. b DSGVO weitergeben. Die Sofort
      GmbH ist Teil der Klarna Group (Klarna Bank AB (publ), Sveavägen 46, 11134
      Stockholm, Schweden). Die Weitergabe Ihrer Daten erfolgt ausschließlich
      zum Zwecke der Zahlungsabwicklung mit dem Zahlungsdienstleister SOFORT und
      nur insoweit, als sie hierfür erforderlich ist. Unter der nachstehenden
      Internetadresse erhalten Sie weitere Informationen über die
      Datenschutzbestimmungen von SOFORT:
      https://www.klarna.com/sofort/datenschutz
    </p>
    <p>
      <strong>Cookies</strong>
      <br />
      Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im
      Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines
      Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein
      Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser
      Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige
      Identifizierung des Browsers beim erneuten Aufrufen der Website
      ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot
      nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren
      ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem
      Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen
      unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten
      werden. Für diese ist es erforderlich, dass der Browser auch nach einem
      Seitenwechsel wiedererkannt wird.
    </p>
    <p>
      Wir verwenden auf unserer Website darüber hinaus Cookies zu dem Zweck,
      eine Analyse des Surfverhaltens unserer Seitenbesucher zu ermöglichen.
    </p>
    <p>
      Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1)
      lit. f DSGVO aus dem berechtigten Interesse an den oben genannten Zwecken.
      <br />
      Die auf diese Weise von Ihnen erhobenen Daten werden durch technische
      Vorkehrungen pseudonymisiert. Eine Zuordnung der Daten zu Ihrer Person ist
      daher nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen
      personenbezogenen Daten von Ihnen gespeichert.
      <br />
      Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation
      ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung
      Sie betreffender personenbezogener Daten zu widersprechen.
      <br />
      Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle
      Kontrolle über die Verwendung von Cookies. Durch die Auswahl
      entsprechender technischer Einstellungen in Ihrem Internetbrowser können
      Sie die Speicherung der Cookies und Übermittlung der enthaltenen Daten
      verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden.
      Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht
      sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      <br />
      Unter den nachstehenden Links können Sie sich informieren, wie Sie die
      Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren)
      können:
      <br />
      Chrome Browser:{' '}
      <a href="https://support.google.com/accounts/answer/61416?hl=de">
        https://support.google.com/accounts/answer/61416?hl=de
      </a>
      <br />
      Internet Explorer:{' '}
      <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
        https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
      </a>
      <br />
      Mozilla Firefox:{' '}
      <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
        https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
      </a>
      <br />
      Safari:{' '}
      <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
        https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
      </a>
    </p>
    <p>
      <strong>Verwendung von YouTube</strong>
      <br />
      Wir verwenden auf unserer Website die Funktion zur Einbettung von
      YouTube-Videos der YouTube LLC. (901 Cherry Ave., San Bruno, CA 94066,
      USA; „YouTube“).
      <br />
      YouTube ist ein mit der Google Inc. (1600 Amphitheatre Parkway, Mountain
      View, CA 94043, USA; “Google”) verbundenes Unternehmen.
      <br />
      Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der
      Website an. Dabei ist die Option „Erweiterter Datenschutzmodus“ aktiviert.
      Dadurch werden von YouTube keine Informationen über die Besucher der
      Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden
      Informationen darüber an YouTube übermittelt und dort gespeichert.
      <br />
      Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und
      Google, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz
      Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube (
      <a href="https://www.youtube.com/t/privacy">
        https://www.youtube.com/t/privacy
      </a>
      ).
    </p>
    <p>
      <strong>Facebook</strong>
      <br />
      Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
      Komponenten des Unternehmens Facebook integriert. Facebook ist ein
      soziales Netzwerk.
    </p>
    <p>
      Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt,
      eine Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht,
      untereinander zu kommunizieren und im virtuellen Raum zu interagieren. Ein
      soziales Netzwerk kann als Plattform zum Austausch von Meinungen und
      Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft,
      persönliche oder unternehmensbezogene Informationen bereitzustellen.
      Facebook ermöglicht den Nutzern des sozialen Netzwerkes unter anderem die
      Erstellung von privaten Profilen, den Upload von Fotos und eine Vernetzung
      über Freundschaftsanfragen.
    </p>
    <p>
      Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way,
      Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener Daten
      Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder
      Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
      Harbour, Dublin 2, Ireland.
    </p>
    <p>
      Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch
      den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher
      eine Facebook-Komponente (Facebook-Plug-In) integriert wurde, wird der
      Internetbrowser auf dem informationstechnologischen System der betroffenen
      Person automatisch durch die jeweilige Facebook-Komponente veranlasst,
      eine Darstellung der entsprechenden Facebook-Komponente von Facebook
      herunterzuladen. Eine Gesamtübersicht über alle Facebook-Plug-Ins kann
      unter https://developers.facebook.com/docs/plugins/?locale=de_DE abgerufen
      werden. Im Rahmen dieses technischen Verfahrens erhält Facebook Kenntnis
      darüber, welche konkrete Unterseite unserer Internetseite durch die
      betroffene Person besucht wird.
    </p>
    <p>
      Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist,
      erkennt Facebook mit jedem Aufruf unserer Internetseite durch die
      betroffene Person und während der gesamten Dauer des jeweiligen
      Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite unserer
      Internetseite die betroffene Person besucht. Diese Informationen werden
      durch die Facebook-Komponente gesammelt und durch Facebook dem jeweiligen
      Facebook-Account der betroffenen Person zugeordnet. Betätigt die
      betroffene Person einen der auf unserer Internetseite integrierten
      Facebook-Buttons, beispielsweise den „Gefällt mir“-Button, oder gibt die
      betroffene Person einen Kommentar ab, ordnet Facebook diese Information
      dem persönlichen Facebook-Benutzerkonto der betroffenen Person zu und
      speichert diese personenbezogenen Daten.
    </p>
    <p>
      Facebook erhält über die Facebook-Komponente immer dann eine Information
      darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn
      die betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite
      gleichzeitig bei Facebook eingeloggt ist; dies findet unabhängig davon
      statt, ob die betroffene Person die Facebook-Komponente anklickt oder
      nicht. Ist eine derartige Übermittlung dieser Informationen an Facebook
      von der betroffenen Person nicht gewollt, kann diese die Übermittlung
      dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite
      aus ihrem Facebook-Account ausloggt.
    </p>
    <p>
      Die von Facebook veröffentlichte Datenrichtlinie, die unter
      https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt Aufschluss
      über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch
      Facebook. Ferner wird dort erläutert, welche Einstellungsmöglichkeiten
      Facebook zum Schutz der Privatsphäre der betroffenen Person bietet. Zudem
      sind unterschiedliche Applikationen erhältlich, die es ermöglichen, eine
      Datenübermittlung an Facebook zu unterdrücken. Solche Applikationen können
      durch die betroffene Person genutzt werden, um eine Datenübermittlung an
      Facebook zu unterdrücken.
    </p>
    <p>
      <strong>
        Nutzung von Social-Media-Plugins von Facebook
        <br />
      </strong>
      Aufgrund meines berechtigten Interesses an der Analyse, Optimierung und
      dem Betrieb meines Online-Angebotes (im Sinne des Art. 6 Abs. 1 lit. f.
      DSGVO), verwendet diese Website das Facebook-Social-Plugin, welches von
      der Facebook Inc. (1 Hacker Way, Menlo Park, California 94025, USA)
      betrieben wird. Erkennbar sind die Einbindungen an dem Facebook-Logo bzw.
      an den Begriffen „Like“, „Gefällt mir“, „Teilen“ in den Farben Facebooks
      (Blau und Weiß). Informationen zu allen Facebook-Plugins finden Sie über
      den folgenden Link: https://developers.facebook.com/docs/plugins/
    </p>
    <p>
      Facebook Inc. hält das europäische Datenschutzrecht ein und ist unter dem
      Privacy-Shield-Abkommen zertifiziert:
      https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
    </p>
    <p>
      Das Plugin stellt eine direkte Verbindung zwischen Ihrem Browser und den
      Facebook-Servern her. Der Websitebetreiber hat keinerlei Einfluss auf die
      Natur und den Umfang der Daten, welche das Plugin an die Server der
      Facebook Inc. übermittelt. Informationen dazu finden Sie hier:
      https://www.facebook.com/help/186325668085084
    </p>
    <p>
      Das Plugin informiert die Facebook Inc. darüber, dass Sie als Nutzer diese
      Website besucht haben. Es besteht hierbei die Möglichkeit, dass Ihre
      IP-Adresse gespeichert wird. Sind Sie während des Besuchs auf dieser
      Website in Ihrem Facebook-Konto eingeloggt, werden die genannten
      Informationen mit diesem verknüpft.
    </p>
    <p>
      Nutzen Sie die Funktionen des Plugins – etwa indem Sie einen Beitrag
      teilen oder „liken“ –, werden die entsprechenden Informationen ebenfalls
      an die Facebook Inc. übermittelt.
      <br />
      Möchten Sie verhindern, dass die Facebook. Inc. diese Daten mit Ihrem
      Facebook-Konto verknüpft, loggen Sie sich bitte vor dem Besuch dieser
      Website bei Facebook aus und löschen Sie die gespeicherten Cookies. Über
      Ihr Facebook-Profil können Sie weitere Einstellungen zur Datenverarbeitung
      für Werbezwecke tätigen oder der Nutzung Ihrer Daten für Werbezwecke
      widersprechen. Zu den Einstellungen gelangen Sie hier:
    </p>
    <p>
      Profileinstellungen bei Facebook:
      https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
      <br />
      Cookie-Deaktivierungsseite der US-amerikanischen Website:
      http://optout.aboutads.info/?c=2#!/
      <br />
      Cookie-Deaktivierungsseite der europäischen Website:
      http://optout.networkadvertising.org/?c=1#!/
      <br />
      Welche Daten, zu welchem Zweck und in welchem Umfang Facebook Daten
      erhebt, nutzt und verarbeitet und welche Rechte sowie
      Einstellungsmöglichkeiten Sie zum Schutz Ihrer Privatsphäre haben, können
      Sie in den Datenschutzrichtlinien von Facebook nachlesen. Diese finden Sie
      hier: https://www.facebook.com/about/privacy/
    </p>
    <p>
      <strong>Instagram</strong>
      <br />
      Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
      Komponenten des Dienstes Instagram integriert. Instagram ist ein Dienst,
      der als audiovisuelle Plattform zu qualifizieren ist und den Nutzern das
      Teilen von Fotos und Videos und zudem eine Weiterverbreitung solcher Daten
      in anderen sozialen Netzwerken ermöglicht.
    </p>
    <p>
      Betreibergesellschaft der Dienste von Instagram ist die Instagram LLC, 1
      Hacker Way, Building 14 First Floor, Menlo Park, CA, USA.
    </p>
    <p>
      Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch
      den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher
      eine Instagram-Komponente (Insta-Button) integriert wurde, wird der
      Internetbrowser auf dem informationstechnologischen System der betroffenen
      Person automatisch durch die jeweilige Instagram-Komponente veranlasst,
      eine Darstellung der entsprechenden Komponente von Instagram
      herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält Instagram
      Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch
      die betroffene Person besucht wird.
    </p>
    <p>
      Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt ist,
      erkennt Instagram mit jedem Aufruf unserer Internetseite durch die
      betroffene Person und während der gesamten Dauer des jeweiligen
      Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite die
      betroffene Person besucht. Diese Informationen werden durch die
      Instagram-Komponente gesammelt und durch Instagram dem jeweiligen
      Instagram-Account der betroffenen Person zugeordnet. Betätigt die
      betroffene Person einen der auf unserer Internetseite integrierten
      Instagram-Buttons, werden die damit übertragenen Daten und Informationen
      dem persönlichen Instagram-Benutzerkonto der betroffenen Person zugeordnet
      und von Instagram gespeichert und verarbeitet.
    </p>
    <p>
      Instagram erhält über die Instagram-Komponente immer dann eine Information
      darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn
      die betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite
      gleichzeitig bei Instagram eingeloggt ist; dies findet unabhängig davon
      statt, ob die betroffene Person die Instagram-Komponente anklickt oder
      nicht. Ist eine derartige Übermittlung dieser Informationen an Instagram
      von der betroffenen Person nicht gewollt, kann diese die Übermittlung
      dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite
      aus ihrem Instagram-Account ausloggt.
    </p>
    <p>
      Weitere Informationen und die geltenden Datenschutzbestimmungen von
      Instagram können unter https://help.instagram.com/155833707900388 und
      https://www.instagram.com/about/legal/privacy/ abgerufen werden.
    </p>
    <p>
      <strong>Kontaktformular</strong>
      <br />
      Besuchern meiner Webseiten biete ich die Möglichkeit, über ein
      Kontaktformular an mich heranzutreten. Die über das Kontaktformular durch
      Sie übermittelten Informationen (Pflichtinformationen sind mit einem
      Sternchenhinweis gekennzeichnet) verwende ich ausschließlich zum Zweck der
      Bearbeitung Ihres Anliegens. Rechtsgrundlagen hierfür sind sowohl Ihre
      Einwilligung im Sinne von Artikel 6 Absatz 1 Buchstabe a) DSGVO als auch
      Artikel 6 Absatz 1 Buchstabe f) DSGVO. Die ordnungsgemäße Bearbeitung
      Ihrer Anliegen ist als berechtigtes Interesse im Sinne der DSGVO
      anzusehen. Sofern Ihre Kontaktaufnahme im Zusammenhang mit einem
      Vertragsverhältnis zwischen Ihnen und mir erfolgt, ist zudem Artikel 6
      Absatz 1 Buchstabe b) DSGVO, d.h. dieses Vertragsverhältnis
      Rechtsgrundlage für die Datenverarbeitung. Ihre Einwilligung in die
      vorstehend erläuterte Datennutzung können Sie jederzeit mit Wirkung für
      die Zukunft kostenfrei durch eine kurze Nachricht an die unter 1.
      angegebenen Kontaktdaten widerrufen. Die Rechtmäßigkeit der Verarbeitung
      auf Grundlage Ihrer Einwilligung bis zum Zeitpunkt Ihres Widerrufs wird
      hiervon nicht berührt. Ich weise allerdings darauf hin, dass ab dem
      Zeitpunkt eines etwaigen Widerrufs eine Bearbeitung Ihres Anliegens nicht
      mehr möglich ist. Außerhalb des Vorliegens eines Widerrufs werden Ihre auf
      die Anfrage bezogenen Daten nach Bearbeitung Ihres Anliegens gelöscht.
    </p>
    <p>
      <strong>Datensicherheit</strong>
      <br />
      Alle von Ihnen persönlich übermittelten Daten, einschließlich Ihrer
      Zahlungsdaten, werden mit dem allgemein üblichen und sicheren Standard SSL
      (Secure Socket Layer) übertragen. SSL ist ein sicherer und erprobter
      Standard, der z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen
      eine sichere SSL-Verbindung unter anderem an dem angehängten s am http
      (also https://…) in der
      <br />
      Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich
      Ihres Browsers. Ich bediene mich im Übrigen geeigneter technischer und
      organisatorischer Sicherheitsmaßnahmen, um Ihre bei mir gespeicherten
      persönlichen Daten gegen Manipulation, teilweisen oder vollständigen
      Verlust und gegen unbefugten Zugriff Dritter zu schützen.
    </p>
    <p>
      <strong>SSL- bzw. TLS-Verschlüsselung</strong>
      <br />
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
      vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
      Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung.
      Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
      des Browsers von “http://” auf “https://” wechselt und an dem
      Schloss-Symbol in Ihrer Browserzeile.
    </p>
    <p>
      Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
      die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
    </p>
    <p>
      <strong>Dauer der Speicherung</strong>
      <br />
      Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die
      Dauer der Gewährleistungsfrist, danach unter Berücksichtigung
      gesetzlicher, insbesondere steuer- und handelsrechtlicher
      Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht,
      sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt
      haben.
    </p>
    <p>
      <strong>Rechte der betroffenen Person</strong>
      <br />
      Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende
      Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung,
      auf Löschung, auf Einschränkung der Verarbeitung, auf
      Datenübertragbarkeit.
      <br />
      Außerdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen
      die Verarbeitungen zu, die auf Art. 6 (1) f DSGVO beruhen, sowie gegen die
      Verarbeitung zum Zwecke von Direktwerbung.
    </p>
    <p>
      Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem
      Impressum.
    </p>
    <p>
      Beschwerderecht bei der Aufsichtsbehörde
      <br />
      Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu
      beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
      personenbezogenen Daten nicht rechtmäßig erfolgt.
    </p>
    <p>
      <strong>Widerspruchsrecht</strong>
      <br />
      Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf
      Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO,
      haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation
      ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu
      widersprechen.
      <br />
      Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten
      beendet, es sei denn, wir können zwingende schutzwürdige Gründe für die
      Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
      überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen dient.
    </p>
    <p>
      Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der
      Direktwerbung, können Sie dieser Verarbeitung jederzeit durch Mitteilung
      an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die
      Verarbeitung der betroffenen Daten zum Zwecke der Direktwerbung.
    </p>
    <p>
      <em>letzte Aktualisierung: 01.04.2019</em>
    </p>
  </Page>
)

export default Agb
